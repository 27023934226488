@import url("https://fonts.googleapis.com/css2?family=PT+Sans+Narrow&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap");
body,
h2 {
  font-family: "PT Sans Narrow", sans-serif;
  margin: 10px;
  padding: 0;
  line-height: 1.8;
  font-weight: 100;
  text-align: center;
}
h1 {
  font-family: "PT Sans Narrow", sans-serif;
  margin: 10px;
  padding: 0;
  line-height: 1;
  font-weight: 100;
  text-align: center;
  text-decoration: underline;
}
p {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  line-height: 1.5;
  margin: 5px;
  padding: 0;
  text-align: center;
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

a {
  color: black;
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  body {
    font-size: 18px;
  }
  p {
    font-size: 20px;
  }
  h1 {
    font-size: 40px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 768px) {
  body {
    font-size: 15px;
  }
  p {
    font-size: 18px;
  }
  h1 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 479px) {
  body {
    font-size: 14px;
  }
  p {
    font-size: 16px;
  }
  h1 {
    font-size: 24px;
  }
}
